<template>
    <generic-page>
        <div class="mt-2">
            <creator-dashboard-url />
        </div>

        <div class="mt-4 d-flex align-items-center justify-content-between">
            <h3 class="branded">Creator</h3>
        </div>

        <creator-review-prompt v-if="onboardingUser.creatorReviewPrompt" />

        <div class="mt-2">
            <creator-pending-credit-summary />
            <creator-guide-summary />
            <creator-fine-summary />
            <creator-jackpot-summary v-if="!onboardingUser.disableInvite" />
            <creator-bonus-summary
                v-if="
                    !onboardingUser.disableInvite &&
                    !onboardingUser.nextGeneration
                "
            />
            <creator-earnings-summary />
            <creator-call-summary />
            <creator-livestream-summary v-if="brand.enableLivestream" />
            <creator-video-summary v-if="brand.enableVideo" />
            <creator-gallery-summary v-if="enableGallery" />
            <creator-paid-share-summary v-if="false" />
            <creator-invite-summary v-if="!onboardingUser.disableInvite" />
            <creator-requests-summary v-if="brand.enableRequest" />
            <creator-leaderboard-summary />
            <creator-help-summary />
        </div>
    </generic-page>
</template>


<script>
import CreatorReviewPrompt from "@/components/creator/review/CreatorReviewPrompt";
import CreatorCallSummary from "@/components/creator/call/CreatorCallSummary";
import CreatorJackpotSummary from "@/components/creator/jackpot/CreatorJackpotSummary";
import CreatorFineSummary from "@/components/creator/fine/CreatorFineSummary";
import CreatorPaidShareSummary from "@/components/creator/paidShare/CreatorPaidShareSummary";
import CreatorDashboardUrl from "@/components/creator/invite/CreatorDashboardUrl";
import CreatorEarningsSummary from "@/components/creator/earnings/CreatorEarningsSummary";
import CreatorInviteSummary from "@/components/creator/invite/CreatorInviteSummary";
import CreatorGallerySummary from "@/components/creator/gallery/CreatorGallerySummary";
import CreatorRequestsSummary from "@/components/creator/requests/CreatorRequestsSummary";
import CreatorHelpSummary from "@/components/creator/help/CreatorHelpSummary";
import CreatorGuideSummary from "@/components/creator/guide/CreatorGuideSummary";
import CreatorPendingCreditSummary from "@/components/creator/pendingCredit/CreatorPendingCreditSummary";
import CreatorLivestreamSummary from "@/components/creator/livestream/CreatorLivestreamSummary";
import CreatorBonusSummary from "@/components/creator/bonus/CreatorBonusSummary";
import CreatorVideoSummary from "@/components/creator/video/CreatorVideoSummary";

// old way
import CreatorLeaderboardSummary from "@/components/creator/summary/CreatorLeaderboardSummary";
import { mapState } from "vuex";

export default {
    mounted() {
        //refresh dashboard, should be renamed to make more sense
        this.$store.dispatch("creatorDashboard/view");
        this.$store.dispatch("creatorGuide/refresh");
    },
    methods: {
        createLivestream() {
            this.$store.dispatch("creatorLivestream/create");
        },
    },
    computed: {
        enableGallery() {
            if (this.onboardingUser.nextGeneration) {
                return false;
            }

            // for now were only showing the tab for creators who already created a gallery
            // during the sunset period
            if (
                this.brand.enableGallery &&
                this.onboardingUser.creatorHasCreatedGallery
            ) {
                return true;
            }

            // technicall still on for FM
            // return this.brand.enableGallery;
            return false;
        },
        enablePost() {
            if (this.onboardingUser.nextGeneration) {
                return false;
            }

            return this.brand.enableFeed;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorDashboard", ["creatorUser"]),
        ...mapState("brand", ["brand"]),
    },
    components: {
        CreatorEarningsSummary,
        CreatorInviteSummary,
        CreatorRequestsSummary,
        CreatorCallSummary,
        CreatorHelpSummary,
        CreatorLeaderboardSummary,
        CreatorPendingCreditSummary,
        CreatorJackpotSummary,
        CreatorDashboardUrl,
        CreatorGallerySummary,
        CreatorFineSummary,
        CreatorGuideSummary,
        CreatorReviewPrompt,
        CreatorPaidShareSummary,
        CreatorLivestreamSummary,
        CreatorBonusSummary,
        CreatorVideoSummary,
    },
};
</script>